
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { Login } from "@/models/Login";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";
import router from "@/router";
import VueRecaptcha from "vue-recaptcha";
import { DeviceUUID } from "device-uuid";
import LocaleSwitcher from "./LocaleSwitcher.vue";

export default Vue.extend({
  name: "app-footer", 
  components: {
    LocaleSwitcher: LocaleSwitcher,
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
});
