
import Vue from "vue";
import AppBar from "@/components/AppBar.vue";
import AppFooter from "@/components/AppFooter.vue";

export default Vue.extend({
  name: "BaseApp",
  data() {
    return {
      sheet: false,
      dialogMultiPleTabs: false,
    };
  },
  components: {
    AppBar,
    AppFooter,
    // AppFooter,
  },
  created() {
    if (
      localStorage.acceptCookies == undefined ||
      localStorage.acceptCookies == null
    ) {
      this.sheet = true;
    }
  },
  methods: {
    acceptCookies() {
      localStorage.setItem("acceptCookies", "true");
      this.sheet = false;
    },
  },
});
