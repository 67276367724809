
import Vue from "vue";

import UserMenu from "@/components/UserMenu.vue";
import ApiMobileService from "@/services/ApiMobile";
import moment from "moment";
import Auth from "@/lib/Auth";
import router from "../router";
import { Overlay } from "@/lib/Overlay";
import Api from "@/services/Api";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "AppBar",
  data: () => ({
    AppNavDrawer: null,
    showMenuTransfPenendetes: false,
    MenuTopo: false,
    AppNavNotificacoes: false,
    countNotUnread: 0,
    notificacoes: [],
    countNotificacoes: 0,
    lang: navigator.language,

    moment: moment,
    menuCarteira: {
      items: [
        {
          title: "components.appBar.template.wallet.deposit",
          link: "/carteira/",
          icon: "mdi-cash-plus",
        },
        {
          title: "components.appBar.template.wallet.withdraw",
          link: "/pix/menuPix",
          icon: "mdi-account-cash-outline",
        },
        {
          title: "components.appBar.template.wallet.transfer",
          link: "/transferencias/interna",
          icon: "mdi-swap-horizontal",
        },
      ],
    },
    // prettier-ignore
    menuCobrancas: {
      items: [
        { title: "components.appBar.template.charges", link: "/cobrancas/", icon: "mdi-hand-coin-outline" },
        {
          title: "components.appBar.template.clients",
          link: "/cobrancas/clientes/",
          icon: "mdi-account-multiple-outline"
        },
      ],
    },

    menuServicos: {
      icon: "mdi-cart-minus",
      title: "Serviços",
      active: false,
      items: [
        {
          title: "Pagamento de contas",
          link: "/pagamentos",
          icon: "mdi-barcode-scan",
        },
        {
          title: "Recarga de celular",
          link: "/recargas/celular",
          icon: "mdi-cellphone",
        },
        {
          title: "Recarga de jogos",
          link: "/recargas/jogos",
          icon: "mdi-gamepad-variant-outline",
        },
        {
          title: "Recarga de TV",
          link: "/recargas/tv",
          icon: "mdi-television-classic",
        },
        {
          title: "Conteúdo digital",
          link: "/recargas/digital",
          icon: "mdi-motion-play-outline",
        },
      ],
    },

    menuMeuNegocio: {
      items: [
        {
          title: "Cobranças",
          link: "/cobrancas",
          icon: "mdi-tag-plus-outline",
        },
        {
          title: "Clientes",
          link: "/cobrancas/clientes",
          icon: "mdi-account-group-outline",
        },
        // { title: "Links de Pagamento", link: "/cobrancas/links" },
        {
          title: "Relatórios",
          link: "/cobrancas/relatorios",
          icon: "mdi-finance",
        },
      ],
    },

    menuPerfil: [
      {
        icon: "mdi-account-circle-outline",
        title: "Meu Perfil",
        active: false,
        items: [
          { title: "Editar Perfil", link: "/perfil" },
          { title: "Segurança", link: "/seguranca" },
        ],
      },
    ],
  }),
  components: {
    // AppDrawer,
    //AppMenuItens,
    UserMenu,
  },
  created() {
    const us = Auth.getUser();
    this.showMenuTransfPenendetes = us.data.apiStatus;

    if (this.$router.currentRoute.fullPath == "/") {
      //this.getUreadNotifications();
    }
    if (
      sessionStorage.unreadNotifications == undefined ||
      sessionStorage.unreadNotifications == null
    ) {
      this.countNotUnread = 0;
    } else {
      this.countNotUnread = sessionStorage.unreadNotifications;
    }

    setInterval(this.checkAuth, 60000);
  },
  methods: {
    CommingSon() {
      Swal.fire(
        "Aguarde...",
        "Em breve esse novo serviço estará disponível",
        "info"
      );
    },
    checkAuth() {
      /* Api.get("/v2/client/auth/ping")
        .then((response) => {
          //
        })
        .catch((error: any) => {
          Auth.logout();
        });
        */
    },
    FecharMenu() {
      this.MenuTopo = false;
    },
    logout() {
      Auth.logout();
    },
    getUreadNotifications() {
      ApiMobileService.get("v1/notification/count/unread").then((response) => {
        sessionStorage.setItem(
          "unreadNotifications",
          response.data.count_unread.toString()
        );
        this.countNotUnread = sessionStorage.unreadNotifications;
      });
    },
    isDemissibleNotification(item: string) {
      if (item === "all") {
        return false;
      } else {
        return true;
      }
    },
    getNotifications() {
      Overlay.show();
      ApiMobileService.get("v1/notification?limit=10&page=0")
        .then((response) => {
          this.countNotUnread = response.data.count_unread;
          this.notificacoes = response.data.rows;
          this.countNotificacoes = response.data.rows.length;

          this.AppNavNotificacoes = true;
          this.makeAllNotificationRead();
        })
        .catch((err) => {
          console.log(err);
          // ...
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    oncloseNotification(item: any) {
      ApiMobileService.delete("v1/notification/" + item._id).then(() => {
        this.countNotUnread = 0;
      });
    },
    makeAllNotificationRead() {
      ApiMobileService.put("v1/notification/mark-all-as-read").then(() => {
        this.countNotUnread = 0;
      });
    },
    goLink(url: string) {
      // console.log(this.$router.currentRoute.fullPath);
      // console.log(url);

      if (this.$router.currentRoute.fullPath != url) {
        router.push("/movimento");
      }
    },
    iOS() {
      return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
  },
});
